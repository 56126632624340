import React, {useContext, useEffect, useState} from 'react';

import $api from "../http";
import {useFetching} from "../hooks/useFetching";
import Loader from "../components/UI/Loader/Loader";
import {Context} from "../index";
import DepotList from "../components/DepotList";



function Depot () {
    const {store} = useContext(Context);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(store.limit)
    const [items, setItems] = useState([]);

    const [totalPage, setTotalpage] = useState(Math.ceil(items.length / store.limit))

    const [fetchDepot, isPageLoading] = useFetching(async () => {
        const response = await $api.get(`/depot`)
            if (response.status == 200) {
                setItems(response.data.depot);
            }
        })



    const changePage = (p) => {

        setPage(p)

    }

    const handlePaginatorCount = (e) => {
        setLimit(e.target.value)
        setPage(1)
    }

    useEffect(() => {

        fetchDepot();
        const interval = setInterval(function(){
                fetchDepot()
        }
            , 300000);
        return () => clearInterval(interval);
    }, [])



    if(isPageLoading) {
        return (
            <Loader />
        );
    }  else {

        return (
            <div className="App">
                <DepotList totalPage={totalPage} depotList={items} />
            </div>
        );
    }
}

export default Depot;
