import React, {useContext, useState} from 'react';

import Row from "react-bootstrap/Row";
import {Table} from "react-bootstrap";
import {useIntl} from "react-intl";

import {Context} from "../index";
import DepotItem from "./UI/DepotItem";




const DepotList = (props) => {
    const {store} = useContext(Context);
    const intl = useIntl();


    return (
        <>
            <Row className="my-3">
                <h3 style={{textAlign: 'center'}}>
                    Склад
                </h3>

            </Row>
            <Row>

            </Row>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Наименование</th>
                    <th>Артикул</th>
                    <th>Серийный номер</th>
                    <th>из Контейнера</th>
                    <th>Повреждения</th>
                    <th>Уценка</th>
                    <th>Фото</th>
                </tr>

                </thead>
                <tbody>


                {props.depotList.map((depot, index) =>

                    <DepotItem key={index} num={index} depot={depot}  />
                )}


                <tr>
                    <td></td>
                    <td><strong>Итого:</strong></td>
                    <td><strong>{props.depotList.length}</strong></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </Table>

        </>


    );
};

export default DepotList;
