import React, {useState} from 'react';
import Form from "react-bootstrap/Form";
import Icon from "@reacticons/bootstrap-icons";

const CarRowOutput = (props) => {
    const row = props.row;
    const handleChange = (e,name) => {
        props.handleChange(e.target.value,name, props.index);

    }

    if(props.status > 2 && row.id > 0){
        return (
            <tr>
                <td>

                </td>
                <td>
                    <Form.Control type="text" value={row.number} readOnly />


                </td>

                <td>
                    <Form.Control value={row.fio} readOnly />
                </td>

                <td>
                    <Form.Control className="countSum" value={row.count} readOnly />
                </td>
                <td>

                </td>
            </tr>
        );
    } else {
        return (
            <tr>
                <td>

                </td>
                <td>
                    <Form.Control type="text" value={row.number} onChange={e => handleChange(e, 'number')} required

                    />


                </td>

                <td>
                    <Form.Control value={row.fio} onChange={e => handleChange(e, 'fio')} type="text" required/>
                </td>

                <td>
                    <Form.Control className="countSum" value={row.count} onChange={e => handleChange(e, 'count')}
                                  type="text" placeholder="" required/>
                </td>
                <td>
                    {!props.del ? '' :
                        <a href="#" className="text-warning" onClick={(e) => props.delete(e, props.index)}>
                            <Icon name="trash"/>
                        </a>
                    }
                </td>
            </tr>
        );
    }
}

export default CarRowOutput;