import React from 'react';
import Fancybox from "./Fancybox";


const DepotItem = (props) => {


   return (
        <>
        <tr>
            <td >{props.num + 1}.</td>


            <td  >{props.depot.gruz}</td>
        <td >{props.depot.artikul}</td>
            <td >{props.depot.serials}</td>
            <td>{props.depot.container_upload}</td>
            <td>{props.depot.comment}</td>
            <td>{props.depot.ucenka}</td>
            <td><Fancybox>
                    {props.depot.photos.map((photo, ind) =>

                        <a key={ind} className="mx-2" data-fancybox="gallery"
                           data-download-src={'https://containera.online/app' + photo.patch}
                           href={'https://containera.online/app' + photo.patch}>Фото{ind + 1}</a>
                    )}</Fancybox>
            </td>

        </tr>
        </>
   );
};

export default DepotItem;
