import React, {useContext, useEffect, useState, useRef} from 'react';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import {Context} from "../index";

import {useIntl} from "react-intl";
import Datelocale from "./UI/Datelocale";
import {Table} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import BrakRow from "./UI/BrakRow";
import Fancybox from "./UI/Fancybox";
import Badge from "react-bootstrap/Badge";
import $api from "../http";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import Form from "react-bootstrap/Form";

const ContainerFormBig = (props) => {
    const {store} = useContext(Context);
    const container = props.container;
    let navigate = useNavigate();
    const intl = useIntl();
    const state = {A:intl.messages.intact,U:intl.messages.damaged,UA:intl.messages.refurbished,WR:intl.messages.refused_to_refurbish}
    const content = {E:intl.messages.empty,F:intl.messages.fullc}
    const clickDepot = () => {
        navigate('/depot');
    }

    const el = useRef(null);
    const [height, setH] = useState();

    useEffect(() => {

        if(el.current !== null && el.current.clientHeight > 24) {
            setH(el.current.clientHeight + 20)
        }

    });
    const handleChange = (e) => {
        let _val = {value:e.target.value, field:'comment_client', id:container.moving_id}
        props.handleSaveMovi(_val)

    }

    const back = () => {
        navigate('/containers');
    }

    const downloadPhoto = async (e) => {
        let moving_id = e.target.dataset.moving;
        let number_for = e.target.dataset.number_for
        let name = number_for + '-' + e.target.dataset.number + '.zip'
        //console.log(e.target);
        await $api.get(`/containers/downloadPhotoNew?id=${moving_id}`, {responseType: 'blob'})
            .then(res=> {
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = url;

                link.setAttribute("download", name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })


    }
    const downloadPhotoBrack = async (e) => {
        let moving_id = e.target.dataset.moving;
        let number_for = e.target.dataset.number_for
        let name = "Брак " + number_for + '-' + e.target.dataset.number + '.zip'
        //console.log(e.target);
        await $api.get(`/containers/downloadPhotoBrack?id=${moving_id}`, {responseType: 'blob'})
            .then(res=> {
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = url;

                link.setAttribute("download", name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })


    }

    const downloadPhotoBrackAkt = async (e) => {
        let moving_id = e.target.dataset.moving;
        let number_for = e.target.dataset.number_for
        let name = "Акт Брак " + number_for + '-' + e.target.dataset.number + '.pdf'
        //console.log(e.target);
        await $api.get(`/containers/downloadPhotoBrackAkt?id=${moving_id}`, {responseType: 'blob'})
            .then(res=> {
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = url;

                link.setAttribute("download", name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })


    }


    return (
        <>
            <Row className="mt-5">
                <Col className="d-grid gap-2 d-md-block" >
                    <Button className="mx-md-1 btn-secondary" type={"button"} onClick={back} >{intl.messages.back}</Button>
                </Col>
            </Row>
        <Row className="mt-5">
            <Card >
                <Card.Body>
                    <Card.Title>{intl.messages.number_container} - <strong>{container.number}</strong></Card.Title>

                        <Row>
                            <Col>
                                <ListGroup className="container-info">
                                    <ListGroup.Item>{intl.messages.type} <span>{container.type}</span></ListGroup.Item>
                                    <ListGroup.Item>{intl.messages.size} <span>{container.size}</span></ListGroup.Item>
                                    <ListGroup.Item>{intl.messages.state} <span>{state[container.state]}</span></ListGroup.Item>
                                    <ListGroup.Item>{intl.messages.content} <span>{content[container.content]}</span></ListGroup.Item>
                                    {(container.content == 'F')?
                                        <>
                                            <ListGroup.Item style={{height: height}}>Груз <span ref={el}>{container.gruz}</span></ListGroup.Item>
                                            <ListGroup.Item>Артикул <span>{container.artikul}</span></ListGroup.Item>
                                            <ListGroup.Item>Вес <span>{container.weight}</span></ListGroup.Item>
                                            <ListGroup.Item>Кол-во мест <span>{container.count}</span></ListGroup.Item>
                                            {(container.count < container.in_count || container.brack_count)?<>
                                                <ListGroup.Item>Брак <span>{container.in_count - container.count}</span>
                                                    <span className="linkTd" style={{right: 10+'px',left:'auto'}} onClick={clickDepot}>Перейти на склад</span></ListGroup.Item>
                                            </> : null

                                            }
                                            <ListGroup.Item>Пломба <span>{container.zpu}</span></ListGroup.Item>
                                            {container.path?<ListGroup.Item>Фото и видео материалы <a href={container.path} target="_blank">{container.path}</a></ListGroup.Item>:null}
                                        </>
                                        : null
                                    }
                                </ListGroup>
                            </Col>
                            <Col>
                                <ListGroup className="container-info">
                                    <ListGroup.Item>Дата завоза <span><Datelocale date={container.created} /></span></ListGroup.Item>
                                    <ListGroup.Item>Релиз <span>№{container.release_id}
                                        {(container.release)? <> - {container.release}</>: null}
                                    </span></ListGroup.Item>
                                    <ListGroup.Item>Перегружен из <span>{container.for_number}</span></ListGroup.Item>
                                    {container.invoice?<ListGroup.Item>ТН № <span>{container.invoice}</span></ListGroup.Item>:null}
                                    <ListGroup.Item>Пломба <span>{container.in_zpu}</span></ListGroup.Item>
                                    <ListGroup.Item>Перевозщик <span>{container.car_firm}</span></ListGroup.Item>
                                    <ListGroup.Item>Номер авто <span>{container.car_number}</span></ListGroup.Item>
                                    <ListGroup.Item>Водитель <span>{container.car_driver}</span></ListGroup.Item>
                                    <ListGroup.Item>Кол-во мест <span>{container.in_count}</span></ListGroup.Item>


                                </ListGroup>
                            </Col>
                        </Row>



                </Card.Body>
            </Card>

            <Card className="mt-5">
                <Card.Body>
                    <Card.Title>Файлы</Card.Title>

                        <Row>
                            <Fancybox>
                                <div className="text-right mb-2 mt-0">
                                <Badge bg="success" className="pointer" data-moving={container.moving_id} data-number={container.number} data-number_for={container.for_number} onClick={downloadPhoto} >Скачать все файлы</Badge>
                                </div>
                            <Table striped bordered hover>
                                <tr>
                                    <th>#</th>
                                    <th>Описание</th>
                                    <th>Дата</th>
                                    <th>Имя файла</th>
                                </tr>
                                <tbody>
                                {container.photos.map((row, index) =>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                            {row.title}
                                        </td>
                                        <td>{row.time}</td>
                                        <td><a key={index} data-fancybox="gallery" data-caption={row.title}
                                                           data-download-src={'https://containera.online/app' + row.patch}
                                                           href={'https://containera.online/app' + row.patch}> {row.filename}</a>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                            </Fancybox>

                        </Row>

                </Card.Body>
            </Card>
            {(container.count < container.in_count )?
                <>
                    <Card className="mt-5">
                        <Card.Body>
                            <Card.Title>Повреждения
                                {container.akt?
                                    <div className="text-right mb-2 mt-0">
                                        <Badge bg="success" className="pointer" data-moving={container.moving_id}
                                           data-number_for={container.for_number}
                                           data-number={container.number} onClick={downloadPhotoBrackAkt}>Скачать Акт повреждений</Badge>
                                </div>:null}
                            </Card.Title>

                            <Row>
                                <Col>

                                    <Alert variant="danger">{container.b_comment}</Alert>

                                    {(container.brak_row.length > 0) ?
                                        <>
                                            <div className="text-right mb-2 mt-0">
                                                <Badge bg="success" className="pointer" data-moving={container.moving_id} data-number_for={container.for_number}
                                                   data-number={container.number} onClick={downloadPhotoBrack}>Скачать все
                                                файлы</Badge>
                                        </div>
                                        <Table striped bordered hover>
                                            <tr>
                                                <th>#</th>
                                                <th>Наименование</th>
                                                <th>Кол-во</th>
                                                <th>Описание</th>
                                                <th>Серийный номер</th>
                                                <th width="80px">Уценка(%)</th>
                                                <th>Фото</th>
                                            </tr>
                                            <tbody>
                                            {container.brak_row.map((row, index) =>
                                                <BrakRow key={index} index={index} brak={row} saveUcenka={props.saveUcenka}/>
                                            )}
                                            </tbody>
                                        </Table>
                                        </>

                                        :null}

                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </>
                : null

            }
            <Card className="mt-5">
                <Card.Body>
                    <Card.Title>Примечание</Card.Title>
                    <Form.Control as="textarea" data-moving={container.moving_id} value={container.comment_client}   placeholder="" onChange={e => handleChange(e)} />
                </Card.Body>
            </Card>
        </Row>
        <Row className="my-5">
            <Col className="d-grid gap-2 d-md-block" >
            <Button className="mx-md-1 btn-secondary" type={"button"} onClick={back} >{intl.messages.back}</Button>
                </Col>
            </Row>
        </>
    );
};

export default ContainerFormBig;

